// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/exam-fe-gateway_uSqJ/node_modules/.pnpm/registry.npmmirror.com+@umijs+runtime@3.5.41_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Global/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/examination",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__examination__index' */'@/pages/examination/index.tsx'), loading: LoadingComponent}),
        "title": "考务平台",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/SiteAuth'), loading: LoadingComponent})]
      },
      {
        "path": "/home/Banner",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Banner__index' */'@/pages/home/Banner/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home/Content/Lateral",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Content__Lateral__index' */'@/pages/home/Content/Lateral/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home/Content/QuestionBankPublic",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Content__QuestionBankPublic__index' */'@/pages/home/Content/QuestionBankPublic/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home/Content/Resource",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Content__Resource__index' */'@/pages/home/Content/Resource/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home/Content",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__Content__index' */'@/pages/home/Content/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home/certificate-query",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__certificate-query__index' */'@/pages/home/certificate-query/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/home",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'@/pages/home/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/news/Search",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__Search__index' */'@/pages/news/Search/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/news",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__index' */'@/pages/news/index.tsx'), loading: LoadingComponent}),
        "title": "政策资讯"
      },
      {
        "path": "/news/:id",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news__id__index' */'@/pages/news/[id]/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/query-service/query-question-bank/columns",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__query-service__query-question-bank__columns' */'@/pages/query-service/query-question-bank/columns.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/query-service/query-question-bank",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__query-service__query-question-bank__index' */'@/pages/query-service/query-question-bank/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question/construction/collect",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__construction__collect__index' */'@/pages/question/construction/collect/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question/construction",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__construction__index' */'@/pages/question/construction/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__index' */'@/pages/question/index.tsx'), loading: LoadingComponent}),
        "title": "题库征集",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/SiteAuth'), loading: LoadingComponent})]
      },
      {
        "path": "/question/object",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__object__index' */'@/pages/question/object/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question/report/MyUpload",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__report__MyUpload__index' */'@/pages/question/report/MyUpload/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question/report/columns",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__report__columns' */'@/pages/question/report/columns.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/question/report",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__question__report__index' */'@/pages/question/report/index.tsx'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
